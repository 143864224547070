import React, { useEffect } from 'react';
import './App.css';
// import { Scanner } from '@yudiel/react-qr-scanner';
import { BarcodeScanner } from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"
import { useState  } from 'react';
function App() {
  console.log('hey1');
  const [number, setNumber] = useState(0);


// useEffect(()=>{
//   navigator.permissions.query({ name: "camera" }).then(function (result) {
//     console.log('result of permission',result.state);
//     if(result.state === 'granted'){
//       console.log('Permission already granted');
//       alert('Permission already granted');
//     } else if(result.state === 'prompt'){
//       console.log('Permission prompt');
//       alert('Permission prompt');
//     }else if(result.state === 'denied'){
//       console.log('Permission denied');
//       alert('Permission denied');
//     }
//   });
  
// },[])

  return (
   
       
     <div >
      {/* a red circle of 400 px */}
      {/* {
        <div style={{width: 400, height: 400, borderRadius: 200, backgroundColor: 'red'}}></div>
      }  */}


      {/* @yudiel/react-qr-scanner */}
      {/* <Scanner

      paused={false}
      styles={{
       container:{
        // width: 400,
        // height: 400,
        }
       ,
        // finderBorder:1000,
      }}
      allowMultipleScans={true}
      formats={['code_128']}
      key={number}
      
      onScan={(result) => {
        console.log('result',);
        // console.log(result[0]?.rawValue)
        setNumber(number + 1);
       if(window?.ReactNativeWebView !==undefined){

         window.ReactNativeWebView.postMessage(result[0]?.rawValue);
       }
        }} 
        /> */}



        {/* react-barcode-scanner */}
<BarcodeScanner 
key={number}

options={{ formats: ['code_128', 'qr_code'] }}
onCapture={(result)=>{
  console.log('res',result?.rawValue);



  setNumber(number + 1);
  if(window?.ReactNativeWebView !==undefined){
    window.ReactNativeWebView.postMessage(result?.rawValue);
  }}} />
         </div>
    
  );
}

export default App;
